import { withStyles } from '@mui/styles';
import { cloneDeep, find, findIndex, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import { getCurrentPageTabs } from '@survey/common/dist/actions/pages.actions';
import { getSurveyAMAMScore, getSurveyScore, saveSurvey, setSurvey, saveSurveyChanges } from '@survey/common/dist/actions/surveys.actions';
import CustomTabs from '@survey/common/dist/components/form-controls/CustomTabs';
import { createSurveyStateForEntity } from '@survey/common/dist/utilities/createSurveyState';
import { calculatePageStatus } from '@survey/common/dist/utilities/surveyUtils';
import { getResource } from '@survey/common/dist/utilities/getResource';
import { hasDelegatePermission } from '@survey/common/dist/utilities/delegates';

class SurveyPageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      page: null,
      pageTabs: [],
      pages: [],
      survey: {},
      techSet: false,
    };

    ['updateSurvey', 'updateSurveyChanges', 'changeSelection', 'handlePreviousPage', 'handleNextPage', 'isFirstPage', 'isLastPage', 'completePage'].map((func) => (this[func] = this[func].bind(this)));
  }

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };
    const { tabs, pages, history, match, survey, surveyType, technologies, resources, auth, delegates, entity } = props;
    console.log('surveyPage.container.js getDerivedStateFromProps', survey);
    if (isEmpty(tabs) || isEmpty(pages)) {
      history.replace(`/surveyLanding/${match.params.id}/home`);
    }

    if (!isEmpty(props.surveyType)) {
      const entity = find(survey.entities, { entityID: Number(props.match.params.entityId) });

      if (surveyType && entity) {
        const surveyState = createSurveyStateForEntity(entity, survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies, resources);
        const pageId = match.params.pageId.startsWith('tq') ? match.params.pageId : Number(match.params.pageId);
        let pageQuestionCount = [];

        const entityPages = cloneDeep(surveyState.pages);
        const pagesWithProgress = entityPages.map((p) => {
          if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
            p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
          }

          return p;
        });

        pagesWithProgress.forEach((p) => {
          const { surveyType, tabs } = surveyState;
          const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);
          pageQuestionCount.push({ pageId: p.pageId, totalQuestions: pageStatus.totalPageQuestions, tabQuestionCount: pageStatus.tabQuestionCount });
        });

        newState = { ...newState, ...surveyState, entity, page: find(surveyState.pages, { pageId }), pageQuestionCount };
      }
    }

    if (!isEmpty(survey) && !isEmpty(tabs) && !isEmpty(surveyType) && !isEmpty(technologies)) {
      newState.pageTabs = getCurrentPageTabs(match.params.pageId, newState.tabs, survey.questions, newState.surveyType.questions, survey.technologyQuestions, newState.surveyType.technologyQuestions);
    }

    newState['pages'] = newState['pages'].filter((page) => {
      const isPermitted = hasDelegatePermission(delegates, auth.email, Number(props.match.params.entityId), page.pageId.toString());
      if (!isPermitted) return false;
      return true;
    });

    return { ...newState, survey };
  }

  handlePreviousPage() {
    const { pages, page } = this.state;
    const curPageIndex = findIndex(pages, { pageId: page.pageId });
    this.changeSelection(curPageIndex === 0 ? undefined : pages[curPageIndex - 1].pageId);
  }

  handleNextPage() {
    const { pages, page } = this.state;
    const curPageIndex = findIndex(pages, { pageId: page.pageId });
    const { delegates, auth } = this.props;
    const { id, entityId } = this.props.match.params;

    //USER IS A TOTAL SURVEY DELEGATE OR delegates is empty {} (its a blank survey and they must be admin) SO LET THEM GO TO NEXT PAGE OR HOME IF ON LAST PAGE
    /*
    if(find(delegates.users, { email: auth.email  }) || Object.keys(delegates).length === 0 || (delegates.users === undefined || delegates.users.length === 0) ) {
      this.changeSelection(curPageIndex === pages.length - 1 ? undefined : pages[curPageIndex + 1].pageId);
    }else{

    const entityDelegates = find(delegates.entities, { entityID: parseInt(entityId) });
    //USER IS NOT A TOTAL SURVEY DELEGATE, CHECK FOR PAGE PERMISSIONS

      if( curPageIndex === pages.length - 1 ){
        this.props.history.replace(`/surveyLanding/${id}/home`);
        return;
      }

      //get an array of all pages ids the user has access to from entityDelegates.categories
      let foundPages = [];
      entityDelegates.categories.map((page) => {
        page.users.map((userItem) => {
          if(auth.email === userItem.email){
            foundPages.push(page.pageID);
          }
        });
      });

      //Check each of those page ids to see if they are a higher index in pages[], if so send them there, otherwise send them home
      let nextPageFound = false;
      if (foundPages.length) {
        pages.map((pageItem, index) => {
          foundPages.map((foundPage) => {
              //console.log('pageItem.pageId: ', pageItem.pageId, 'index: :', index, 'curPageIndex: ', curPageIndex, 'foundPage: ', foundPage);
            if (pageItem.pageId === foundPage && index > curPageIndex) {
              //if index is greater than curPageIndex, send them there
                //console.log('FOUND A PAGE TO SEND THEM TO, pageItem index is: ', index, 'pageItem.pageId is: ', pageItem.pageId, 'curPageIndex is: ', curPageIndex, 'pages[index].pageId: ', String(pages[index].pageId), 'curPageIndex === pages.length - 1 evaluates to: ', curPageIndex === pages.length - 1);
                nextPageFound = true;
                this.changeSelection(curPageIndex === pages.length - 1 ? undefined : pages[index].pageId);
            }
          });
          if(!nextPageFound) {
              this.props.history.replace(`/surveyLanding/${id}/home`);
          }
        });
      }else{
        this.props.history.replace(`/surveyLanding/${id}/home`);
      }
    }*/
    this.changeSelection(curPageIndex === pages.length - 1 ? undefined : pages[curPageIndex + 1].pageId);
  }

  changeSelection(selection) {
    const { id, entityId } = this.props.match.params;

    if (selection === undefined) {
      this.props.history.replace(`/surveyLanding/${id}/home`);
    } else {
      this.props.history.push(`/surveyLanding/${id}/entity/${entityId}/page/${selection}`);
    }
  }

  //Always return false so that when a user hits next section on the last page it will redirect to the survey home page
  isFirstPage() {
    return false;
  }

  //Always return false so that when a user hits previous section on the first page it will redirect to the survey home page
  isLastPage() {
    return false;
  }

  async updateSurvey(surveyID, entityID, data) {
    const { surveyType } = this.props;

    let entities = [...this.state.survey.entities];
    const idx = findIndex(this.state.survey.entities, { entityID });
    // Exit if we can't find the entity to update.

    if (idx === -1) return;

    entities[idx] = { ...entities[idx], data };
    let survey = cloneDeep(this.state.survey);
    survey.entities = entities;

    /* Set the status to In Progress if it hasn't been already */
    if (survey.status !== 'In Progress') {
      survey.status = 'In Progress';
    }

    if (surveyType.surveyTemplate === 'One Page') {
      this.setState({ survey, entity: entities[idx] });
    } else {
      await this.saveSurvey(survey).then((json) => {
        this.props.surveyIsLoaded(survey);
      });
    }
  }
  async updateSurveyChanges(surveyID, entityID, changes) {
    const { pages, entity, tabs } = this.state;
    const { surveyType, technologies, match, resources } = this.props;
    const pageProgress = {};
    let survey = cloneDeep(this.state.survey);

    if (!changes.changes.pageProgress) {
      let updatedEntity = cloneDeep(entity);
      console.log('surveyPage.container.js updateSurveyChanges', changes);
      updatedEntity.data = changes.updatedEntityData;
      cloneDeep(pages).forEach((p) => {
        if (survey.pageProgress && survey.pageProgress[`${updatedEntity.entityID}-${p.pageId}`] && survey.pageProgress[`${updatedEntity.entityID}-${p.pageId}`].completed) {
          p.pageProgress = survey.pageProgress[`${updatedEntity.entityID}-${p.pageId}`];
        } else {
          const pageStatus = calculatePageStatus(`${p.pageId}`, updatedEntity, tabs, surveyType, survey, technologies);

          const percentComplete = (pageStatus.totalQuestionsAnswered / pageStatus.totalQuestions) * 100;

          p.pageProgress = {
            completed: false,
            percentComplete: isNaN(percentComplete) ? 0 : Math.round(percentComplete),
          };
        }

        pageProgress[`${entity.entityID}-${p.pageId}`] = p.pageProgress;

        return p;
      });
      
      changes.pageProgress = { ...survey.pageProgress, ...pageProgress };
    } else {
      changes.pageProgress = changes.changes.pageProgress;

      delete changes.changes.pageProgress;
    }

    if (survey.status === 'Not Started') {
      changes.updatedStatus = 'In Progress';
    }

    await this.props.saveSurveyChanges(surveyID, entityID, changes).then((json) => {
      this.props.surveyIsLoaded();
    });
  }

  //removed - not being used
  //async componentWillUnmount() {
  //  let entities = [...this.state.survey.entities];
  //  let survey = cloneDeep(this.state.survey);
  //  survey.entities = entities;

  /* Set the status to In Progress if it hasn't been already */
  //  if (survey.status !== 'In Progress') {
  //    survey.status = 'In Progress';
  //  }

  // this.saveSurvey(survey);
  //}

  async saveSurvey(survey) {
    const { surveyType, technologies, match, resources } = this.props;
    const { pages, entity, tabs } = this.state;

    delete survey.bsonId;
    // TODO:  We should just remove the questions and technologyQuestions entirely but right now that will delete them from the survey
    survey.questions.forEach((q) => {
      delete q.bsonId;
    });
    survey.technologyQuestions.forEach((q) => {
      delete q.bsonId;
    });

    const pageProgress = {};

    cloneDeep(pages).forEach((p) => {
      if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`] && survey.pageProgress[`${entity.entityID}-${p.pageId}`].completed) {
        p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
      } else {
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        const percentComplete = (pageStatus.totalQuestionsAnswered / pageStatus.totalQuestions) * 100;

        p.pageProgress = {
          completed: false,
          percentComplete: isNaN(percentComplete) ? 0 : Math.round(percentComplete),
        };
      }

      pageProgress[`${entity.entityID}-${p.pageId}`] = p.pageProgress;

      return p;
    });

    survey.pageProgress = { ...survey.pageProgress, ...pageProgress };

    const response = await this.props.saveSurvey(match.params.id, survey);

    if (response.type !== 'SAVE_SURVEY_SUCCESS' && this.props.handleToastMessage) {
      var msg = getResource(resources, survey.language, 'Message', "We're sorry, there was an error saving your survey data.  Please try again.");
      this.props.handleToastMessage(msg, true);
      console.log('SAVE SURVEY ERROR: ', response);
    }
  }

  async completePage(page, changes) {
    let survey = cloneDeep(this.state.survey);
    const entityID = this.state.entity.entityID;
    survey.pageProgress[`${entityID}-${page.pageId}`] = {
      percentComplete: 100,
      completed: true,
    };
    changes.pageProgress = { ...survey.pageProgress };
    await this.props.saveSurveyChanges(survey.surveyGuid, entityID, changes).then((json) => {
      this.props.surveyIsLoaded();
    });
    //await this.saveSurvey(survey);
  }

  render() {
    const {
      answers,
      classes,
      countriesList,
      isLoading,
      isSaving,
      match,
      productsList,
      regionsList,
      setTechnologyName,
      survey,
      technologiesList,
      vendorsList,
      surveyType,
      delegates,
      regions,
      resources,
      isApiError,
      handleToastMessage,
    } = this.props;
    const { questions, technologyQuestions, entity, pages, techSet, page, pageTabs, pageQuestionCount } = this.state;

    const isTechPage = match.params.pageId.startsWith('tq');

    if (isTechPage && !isEmpty(pages) && !techSet) {
      setTechnologyName(find(pages, { pageId: match.params.pageId }).pageName);

      this.setState({ techSet: true });
    }

    if (page && survey.pageProgress && survey.pageProgress[`${entity.entityID}-${page.pageId}`]) {
      page.pageProgress = survey.pageProgress[`${entity.entityID}-${page.pageId}`];
    }

    //For 'Single Hospital Health System' and 'IDS/RHA' we need to hide the Beds Licensed and Primary Service questions (45 and 132)
    // TODO: Find a different way to hide questions
    //let filteredQuestions = questions;
    //if (entity) {
    //  if (entity.haEntityTypeID === 6 || entity.haEntityTypeID === 8) {
    //    filteredQuestions = questions.filter(theQuestions =>
    //      theQuestions.questionID != 45 && theQuestions.questionID != 132 && theQuestions.questionID != 1006 && theQuestions.questionID != 1460 && theQuestions.questionID != 1459 ? true : false
    //    );
    //  }
    //}

    return (
      <div className={classes.root}>
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <div className={classes.content}>
            {isSaving && <LoadingOverlay />}
            <CustomTabs
              key={survey.language}
              isApiError={isApiError}
              answers={answers}
              countriesList={countriesList}
              delegates={delegates}
              entity={entity}
              inline={false}
              isInternal={false}
              isFirstPage={this.isFirstPage}
              isLastPage={this.isLastPage}
              handlePreviousPage={this.handlePreviousPage}
              handleNextPage={this.handleNextPage}
              completePage={this.completePage}
              pageId={String(page.pageId)}
              page={page}
              pages={pages}
              productsList={productsList}
              questions={questions}
              regionsList={regionsList}
              saveSurvey={this.updateSurvey}
              saveSurveyChanges={this.updateSurveyChanges}
              surveyType={surveyType}
              tabs={pageTabs}
              technologiesList={technologiesList}
              technologyQuestions={technologyQuestions}
              vendorsList={vendorsList}
              regions={regions}
              activeTab={this.props.activeTab}
              onChangeTab={this.props.onChangeTab}
              survey={survey}
              resources={resources}
              pageQuestionCount={pageQuestionCount}
            />
          </div>
        )}
      </div>
    );
  }
}

SurveyPageContainer.propTypes = {
  answers: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  //entityAmbulatories: PropTypes.array,
  //entityFreeStandingDataCenters: PropTypes.array,
  //entityHomeHealths: PropTypes.array,
  //entityHospitals: PropTypes.array,
  //entityInHospitalDataCenters: PropTypes.array,
  //entitySubAcutes: PropTypes.array,
  //entityUntetheredAmbulatories: PropTypes.array,
  pages: PropTypes.array.isRequired,
  productsList: PropTypes.array.isRequired,
  regionsList: PropTypes.array.isRequired,
  setTechnologyName: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
  surveyType: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  technologies: PropTypes.array.isRequired,
  technologiesList: PropTypes.array.isRequired,
  vendorsList: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
};

const styles = () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  formWrapper: {
    overflowY: 'scroll',
    flexGrow: 1,
    height: 100,
  },
  content: {
    backgroundColor: 'white',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden',
  },
});

const mapStateToProps = (state, props) => {
  return {
    isApiError: state.surveys.get('isApiError'),
    isLoading: state.entities.get('isLoading'),
    isSaving: state.surveys.get('isSaving'),
    //survey: state.surveys.get('survey'),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    getSurveyAMAMScore,
    getSurveyScore,
    saveSurvey,
    saveSurveyChanges,
    setSurvey,
  })(SurveyPageContainer)
);
